import axios from "axios";

const instance = axios.create({
  // baseURL: "http://13.233.142.228:4000",
  // baseURL: "http://65.2.175.154:8000",
  // baseURL: "https://api.astrogyata.in/api",
  baseURL: "https://api.astrogyata.in/",
  // https://api.astrogyata.in/
  // baseURL:"http://192.168.1.145:4000/"
  // baseURL: "http://localhost:5000/",
});

export default instance;
